.highlighter-rouge .highlight,
pre,
code {
  background: #F6F8FA;
  background-color: #F6F8FA;
  font: 14px $code-font-family;
  line-height: 1.45;
}

.home {
  a.primary-links {
    margin: 20px;
    padding: 20px;
    background: #eee;
    border-radius: 8px;

    @include media-query($on-palm) {
      display: block;
    }
  }
}

blockquote {
  letter-spacing: 0px;
}

.site-header {
  background: #f8f8f8;
}

.site-title:hover {
  text-decoration: none;
}

.site-title img {
  border-radius: 50px;
  margin-right: 10px;
}

strong,
em,
h1, h2, h3, h4, h5, h6 {
  color: #111;
}

hr {
  margin: 50px 0 40px 0;
  border-top: 1px solid #ddd;
  color: #fff;
}

.site-title {
  @include relative-font-size(1);
}

.post-title {
  @include relative-font-size(2.1);
  font-weight: bold;
  line-height: $base-line-height * $base-font-size * 1.7;
}

.post-content {
  h2 {
    @include relative-font-size(1.7);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
    &:not(.mt-0) {
      margin-top: 4rem;
    }
  }

  h3 {
    @include relative-font-size(1.4);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
    &:not(.mt-0) {
      margin-top: 3rem;
    }
  }

  h4 {
    @include relative-font-size(1.2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
    &:not(.mt-0) {
      margin-top: 2rem;
    }
  }
}

img.img-portfolio {
  margin-bottom: 4px;
  width: 200px;
  border-radius: 5px;
  opacity: 0.3;
}

.tag {
  background: $brand-color;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  margin-right: 2px;
}

.pull-right {
  float: right;
}

.text-align-right {
  text-align: right;
}
